import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import moment from "moment";
import ConfirmDialog from "@/view/pages/ConfirmDialog";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },

    name: "shopping-event-post",
    data() {
        return {
            requiredRule: [v => !!v || "Field is required"],
            isSaving: false,
            shoppingEventPostId: null,
            shoppingEventPost: {
                id: "",
                date: null,
                shopProductPost: {
                    shopProduct: null
                },
                shoppingEvent: null,
                discountPercent: null,
                finalDiscountPercent: null,
                finalPrice: null,
                quantity: null,
                status: "",
                donationAmount: null
            },
            statuses: ["IN_REVIEW", "APPROVED", "DENIED"],
            valid: false,
            cities: [],
            search: "",
            citiesIsLoading: false,
            approveDialog: false,
            alert: false,
            alertMessage: "",
        };
    },
    created() {
        this.shoppingEventPostId = this.$route.params.shoppingEventPostId;
        let title =
            this.shoppingEventPostId != null ? "Shopping event post details" : "Insert new shopping event post";
        this.$store.dispatch(SET_BREADCRUMB, [{title: title}]);
        if (this.shoppingEventPostId != null) {
            ApiService.get(`api/shopping-event-post`, `${this.shoppingEventPostId}`)
                .then(response => {
                    this.$log.debug("Shopping event post: ", response.data);
                    this.shoppingEventPost = response.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
        }
        this.searchCities();
    },
    components: {
        ConfirmDialog
    },
    methods: {
        async saveChanges(item) {
            if (
                await this.$refs.confirm.open (
                    "Confirm" ,
                    "Are you sure you want to save changes for this post ?"
                )
            ) {
                this.saveChangesForPost ( item );
            }
        } ,
        async deletePost(item) {
            if (
                await this.$refs.confirm.open (
                    "Confirm" ,
                    "Are you sure you want to delete this post ?"
                )
            ) {
                this.deleteShoppingPost ( item );
            }
        } ,
        previousState() {
            this.$router.go(-1);
        },
        endsWith(suffix, str) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        },
        paddingSize(value) {
            if (this.endsWith("==", value)) {
                return 2;
            }
            if (this.endsWith("=", value)) {
                return 1;
            }
            return 0;
        },
        searchCities() {
            // Items have already been loaded
            if (this.cities.length > 0) return;

            // Items have already been requested
            if (this.citiesIsLoading) return;

            this.citiesIsLoading = true;
            ApiService.query("api/cities")
                .then(res => {
                    this.cities = res.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.citiesIsLoading = false));
        },
        returnToReview() {
            this.shoppingEventPost.status = 'IN_REVIEW';
            this.changeStatus(this.shoppingEventPost)
        },
        approve() {
            this.shoppingEventPost.status = 'APPROVED';
            this.changeStatus(this.shoppingEventPost)
        },
        deny() {
            this.shoppingEventPost.status = 'DENIED';
            this.changeStatus(this.shoppingEventPost)
        },
        validateAndSave() {
            this.changeStatus(this.shoppingEventPost);
        },
        saveChangesForPost(shoppingEventPost){
            this.$log.debug("save changes for post: ", shoppingEventPost);
            this.loading = true;
            ApiService.put(`api/shopping-event-post`, shoppingEventPost)
                .then(response => {
                    this.$log.debug("shopping event post updated: " + response);
                    this.alert = false;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.alertMessage = error.response.data.message;
                    this.alert = true;
                    this.errored = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.isSaving = false;
                    console.log("Alert in finally is " + this.alert);
                    if (this.alert === false) {
                        this.previousState();
                    }
                });
        },
        deleteShoppingPost(shoppingEventPost){
            this.$log.debug("save changes for post: ", shoppingEventPost);
            this.loading = true;
            ApiService.delete ( `api/shopping-event-post/${shoppingEventPost.id}` )
                .then(response => {
                    this.$log.debug("shopping event post deleted: " + response);
                    this.alert = false;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.alertMessage = error.response.data.message;
                    this.alert = true;
                    this.errored = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.isSaving = false;
                    console.log("Alert in finally is " + this.alert);
                    if (this.alert === false) {
                        this.previousState();
                    }
                });
        },
        changeStatus(shoppingEventPost) {
            this.$log.debug("shoppingEventPost: ", shoppingEventPost);
            let changeStatusAndDonationPercent = {};
            changeStatusAndDonationPercent["id"] = shoppingEventPost.id;
            changeStatusAndDonationPercent["status"] = shoppingEventPost.status;
            changeStatusAndDonationPercent["donationAmount"] = shoppingEventPost.donationAmount;
            this.loading = true;
            ApiService.put(`api/shopping-event-post/change-status-and-donation-percent`, changeStatusAndDonationPercent)
                .then(response => {
                    this.$log.debug("Status and donation updated: " + response);
                    this.alert = false;
                    this.approveDialog = false;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.alertMessage = error.response.data.message;
                    this.alert = true;
                    this.errored = true;
                    this.approveDialog = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.isSaving = false;
                    console.log("Alert in finally is " + this.alert);
                    if (this.alert === false) {
                        this.previousState();
                    }
                });
        },
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        ...mapGetters(["currentUser"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        },
        dateFormatted: {
            get() {
                if (this.shoppingEventPost.date == null || this.shoppingEventPost.date === "")
                    return null;
                return moment
                    .utc(this.shoppingEventPost.date)
                    .local()
                    .format("yyyy-MM-DD HH:mm:ss");
            },
            set(value) {
                this.promotion.start = value;
            }
        },
    }
};
